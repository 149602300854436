<template>
  <div>
    <v-container>
      <v-row class="justify-center d-flex mt-n16">
        <v-col class="mx-auto">
          <v-card
            class="pa-6 card-shadow border-radius-xl bg-white-transparent"
          >
            <v-alert
              v-if="error_msg"
              :ripple="false"
              key="danger"
              close-icon="fas fa-times font-size-root text-white"
              :class="`alert alert-danger mx-4`"
              dismissible
              dark
            >
              <span>
                {{ error_msg }}
              </span>
            </v-alert>
            <h3 class="text-center text-h5 font-weight-bolder mb-2 text-login">
              {{ $t("Login") }}
            </h3>
            <div class="card-padding">
              <v-form ref="loginForm" @submit="handleLogin">
                <v-text-field
                  v-model="form.email"
                  :rules="rules.email"
                  height="40"
                  placeholder="Email"
                  outline
                  background-color="rgba(255,255,255,.9)"
                  color="rgba(0,0,0,.6)"
                  light
                  outlined
                  hide-details=""
                  class="input-style font-size-input border border-radius-md placeholder-lighter text-color-light mb-4"
                >
                </v-text-field>

                <v-text-field
                  outline
                  hide-details
                  v-model="form.password"
                  :rules="rules.password"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'"
                  lazy-validation
                  @click:append="show = !show"
                  placeholder="Password"
                  outlined
                  background-color="rgba(255,255,255,.9)"
                  color="rgba(0,0,0,.6)"
                  light
                  height="40"
                  class="input-style font-size-input border border-radius-md placeholder-lighter text-color-light mb-4"
                >
                </v-text-field>

                <v-btn
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="font-weight-bold text-uppercase btn-default bg-gradient-default py-2 px-6 me-2 mt-6 mb-2 w-100"
                  color="#5e72e4"
                  small
                  type="submit"
                  :loading="loading"
                >
                  {{ $t("auth.login") }}
                  <span slot="loader">
                    <v-progress-circular
                      style="width: 20px; height: 20px"
                      indeterminate
                      color="white"
                    ></v-progress-circular>
                  </span>
                </v-btn>
              </v-form>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      valid: false,
      show: false,
      form: {
        // email: "admin@admin.com",
        email: "",
        paswword: "",
      },
      rules: {
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
        password: [
          (v) => !!v || "Password is required",
          (v) => (v && v.length >= 6) || "minimum 6 characters",
        ],
      },
      errors: {},
      error_msg: "",
      loading: false,
    };
  },
  methods: {
    validate() {
      // this.valid=this.$refs.loginForm.validate();
      return this.$refs.loginForm.validate();
    },
    handleLogin(e) {
      e.preventDefault();
      this.error_msg = "";
      this.loading = true;
      if (this.validate()) {
        this.$store.dispatch("auth/login", this.form).then(
          () => {
            this.$router.push("/dashboard");
          },
          (error) => {
            this.loading = false;
            // console.log(error.response.data.message);
            this.error_msg =
              (error.response && error.response.data.message) || error.message;
            error.toString();
          }
        );
      } else {
        this.loading = false;
        this.error_msg = this.$i18n.t("auth.Please Check errors and try again");
        return;
      }
    },
  },
  mounted() {
    document.title = this.$i18n.t("auth.login");
  },
};
</script>
